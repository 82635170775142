import MainComponent from "./Components/Main/MainComponent";

function App() {
  return (
      <div className="App">
        <MainComponent />
      </div>
  );
}

export default App;
