import { useEffect, useMemo } from "react";
import { SiteContents } from "./TextContentItemStore";


export interface TextContentComponentProps {
    contentName:string;
  }

const TextContentComponent = (props:TextContentComponentProps) => {
    const {contentName}=props;
    const content = useMemo(()=>SiteContents.find(c => c.id === contentName)?.jsxCode ?? null,[contentName]);

  useEffect(()=>{
    var scrollOptions = {
        left: 0,
        top: 0
      }
    window.scrollTo(scrollOptions);
  },[content])

  return(
      <div className="text-content-container">
          {content}
      </div>
  );
}
export default TextContentComponent;