import { useEffect, useRef, useState } from "react";
import { MenuItems } from "./MenuItems";
import { ReactComponent as Logo } from '../../assets/logo.svg';

export interface MenuComponentProps {
    currentItem: string;
    responsive: string;
    onSelectItem: (selectedItem: string) => any;
}

const MenuComponent = (props: MenuComponentProps) => {
    const [showPopupBar, setShowPopupBar] = useState<boolean>(false);
    const refMenuContainer = useRef<HTMLDivElement | null>(null);
    const scrollPosition = useRef<number>(0);
    const lastScrollPosition = useRef<number>(0);
    const scrollUpMenuHeight = useRef<number>(0);

    useEffect(() => {
        function toggleContainer() {
            if ((scrollPosition.current - lastScrollPosition.current < -60) || (scrollPosition.current - lastScrollPosition.current > 60)) {
                if (Math.abs(scrollPosition.current) > (refMenuContainer.current?.getBoundingClientRect().height ?? 0) && document.body.getBoundingClientRect().top > scrollPosition.current) {
                    setShowPopupBar(true);
                } else {
                    setShowPopupBar(false);
                    scrollUpMenuHeight.current = 0;
                }
                lastScrollPosition.current = scrollPosition.current;
            }
            scrollPosition.current = (document.body.getBoundingClientRect()).top;

        }
        window.addEventListener("scroll", toggleContainer);

        return function Cleanup() {
            window.removeEventListener("scroll", toggleContainer);
        }

    }, [])

    const handleClickMenu = (linkName: string) => {
        setShowPopupBar(false);
        props.onSelectItem(linkName);
    }


    const MenuBar = () => {
        return <div className="menu-bar">
            {MenuItems.map((menuItem, index) =>
                <button
                    key={index}
                    onClick={() => handleClickMenu(menuItem.linkName)}
                    className={`menu-item${props.currentItem === menuItem.linkName ? " active" : ""}`}>{menuItem.description}
                </button>)}
        </div>;
    }

    const PopupBar = () => {
        return <div className={`popup-bar ${showPopupBar ? "expanded" : "collapsed"}`} >
            <MenuBar />
        </div>;
    }

    return <>
        <div ref={refMenuContainer}
            className="menu-container">
            <div className="menu-header">
                <div className="bar-images">
                    <Logo />
                </div>
                <div className="motto">
                    <span className="grey">Van vormgeving tot unieke huisstijl</span><br />
                    <span className="pink">Uw verhaal visualiseren</span>
                </div>
            </div>
            <MenuBar />
        </div>
        <PopupBar />
    </>

}

export default MenuComponent;