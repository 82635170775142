import { useState } from "react";
import MenuComponent from "../Menu/MenuComponent";
import TextContentComponent from "../TextContent/TextContentComponent";

const MainComponent = () => {
    const page = new URLSearchParams(window.location.search)?.get('page') ?? "mijnWerk";
    const [currentItem, setCurrentItem] = useState<string>(page);

    return <>
        <MenuComponent currentItem={currentItem}
            responsive="desktop"
            onSelectItem={(i) => setCurrentItem(i)} />
        <TextContentComponent contentName={currentItem} />
        <div className="footer">Jester Marketing & Design - info@jestermarketing.nl - Tel. 06 55885868</div>
    </>

}

export default MainComponent;