import { CSSProperties, useEffect, useRef, useState } from "react";
import { WorkItem } from "./WorkItem";
import { illustations, workItems } from "./WorkItemStore";
import { CloseIcon } from "../Icons/CloseIcon";

export interface MyWorkItemsComponentProps {

}

const assetFolder = "/assets/";
const tilesFolder = assetFolder + "project-tiles/";
const detailsFolder = assetFolder + "project-details/";

const MyWorkItemsComponent = (props: MyWorkItemsComponentProps) => {
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [mediaWidth, setMediaWidth] = useState<number>(Math.min((window.innerWidth - 24), 1040));
    const tileWidth = useRef(window.innerWidth >= 1024 ? 192 : 152);

    useEffect(() => {
        function calcMediaWidth() {
            tileWidth.current = window.innerWidth >= 1024 ? 192 : 132;
            setMediaWidth(Math.min((window.innerWidth - 24), 1040));
        }
        window.addEventListener("resize", calcMediaWidth);

        return function Cleanup() {
            window.removeEventListener("resize", calcMediaWidth);
        }

    }, [])

    const WorkItemTile = (wiProps: { workItem: WorkItem }) => {

        return <div key={wiProps.workItem.id}
            id={`tile_${wiProps.workItem.id}`}
            className="tile"
            style={{ width: `${tileWidth.current}px`, height: `${tileWidth.current}px` }}
            onClick={() => setSelectedItem(wiProps.workItem.id)}>

            {wiProps.workItem.tileImage ? <img src={process.env.PUBLIC_URL + tilesFolder + wiProps.workItem.tileImage + ".jpg"} alt={wiProps.workItem.title} /> : null}
        </div>
    }

    const PopupItem = () => {
        let curItem: WorkItem | undefined = workItems.find(wi => wi.id === selectedItem);
        if (!curItem) curItem = illustations.find(wi => wi.id === selectedItem);
        if (!curItem) return null;
        return (
            <div className="workitem-detail-backdrop" onClick={() => setSelectedItem(null)}>
                <div className="workitem-detail-wrapper">
                    <div className={`workitem-detail-container ${!curItem.description ? "no-description" : ""}`} onClick={(e) => { e.stopPropagation() }}>
                        <button className="close-button" onClick={() => setSelectedItem(null)}><CloseIcon /></button>
                        <div className="workitem-detail-content">
                            {curItem.description && <div id="det-title" className="workitem-detail-title">{curItem.title}</div>}
                            {curItem.description && <div id="det-text" className="workitem-detail-text">{curItem.description}</div>}
                            <div id="det-image" className="workitem-detail-image">
                                {curItem.detailImage ? <img src={process.env.PUBLIC_URL + detailsFolder + curItem.detailImage + ".jpg"} alt={curItem.title} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let minGap = 16;
    let initalColumns = Math.round(mediaWidth / (tileWidth.current + minGap));
    let actualColumns = initalColumns * (tileWidth.current + minGap) > mediaWidth ? initalColumns - 1 : initalColumns;
    let spaceLeft = mediaWidth - (actualColumns * tileWidth.current);
    let gap = spaceLeft / actualColumns;
    let style: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: `repeat(${actualColumns}, ${tileWidth.current}px)`,
        gap: `${gap}px`,
        marginTop: '24px',
    }

    const refr = window.location.href;

    const workItemContent = refr.includes("jettiescholten") ? <>
        <h2>Illustraties</h2>
        <div className="tile-container" style={style}>
            {illustations.map(wi => <WorkItemTile key={wi.id} workItem={wi} />)}
        </div>
    </>
        : <>
            <h2>Design</h2>
            <div className="tile-container" style={style}>
                {workItems.map(wi => <WorkItemTile key={wi.id} workItem={wi} />)}
            </div>
        </>;

    return <>
        <div className="workitem-container">
            {workItemContent}
        </div>
        {selectedItem !== null ? <PopupItem /> : null}
    </>;

}

export default MyWorkItemsComponent;