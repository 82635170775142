import MyWorkItemsComponent from "../MyWorkItems/MyWorkItemsComponent";
import { TextContentObject } from "./TextContentObject";

export const SiteContents: TextContentObject[] = [
    {
        id: "workshops",
        jsxCode:
            <>
                <div className="text-content">
                    <div >
                        <img src={`${process.env.PUBLIC_URL}/assets/pictures/workshop.jpg`} alt="workshops" width="60%" style={{ float: "right", paddingTop: "64px", paddingBottom: "16px" }} />
                        <div>
                            <h1 style={{ marginBottom: "0px" }}>WORK<span className="emp">SHOP</span> </h1>
                        </div>
                        <div>

                            <h2>tekenen voor kinderen</h2>
                            Houd je van tekenen of wil je beter leren tekenen?<br />
                            Kom dan naar een leuke workshop tekenen voor jonge kunstenaars tussen de 8 en 12 jaar.<br />
                            <br />
                            Je krijgt een echte buiten tekenles, in de Groentetuin bij Weij. Illustrator Jettie Scholten verzorgt de les. Jettie tekent al sinds dat ze zelf een klein meisje was. Zij heeft van haar hobby haar werk gemaakt en werkt als vormgever en illustrator. Ook in hare vrije tijd doet zij dit met veel plezier samen met haar twee kinderen.<br />
                            <br />
                            <b>Kosten:</b><br />
                            €17,50 per workshop.<br />
                            Inclusief begeleiding, tekenmateriaal, limonade met wat lekkers.<br />
                            <br />
                            <b>Wanneer:</b><br />
                            Woensdagmiddag 22 mei, 19 juni en 17 juli van 14:00 - 15:30.<br />
                            Iedere workshop is weer anders. Je kunt je dus ook alle 3 de lessen volgen.<br />
                            <br />
                            <b>Waar:</b><br />
                            bij Weij Maassluis,<br />
                            <a rel="noreferrer" target="_blank" href="https://www.google.com/maps/place/Vlaardingsedijk+5,+3143+LN+Maassluis/@51.9129245,4.2601383,17z/data=!3m1!4b1!4m6!3m5!1s0x47c44c9cabaaf649:0x2be7c757598ab12b!8m2!3d51.9129245!4d4.2627132!16s%2Fg%2F11c4zffrmh?entry=ttu">
                                Vlaardingsedijk 5, 3143 LN Maassluis</a><br />
                            (vlakbij Rotterdam en Den Haag)<br />
                            <br />
                            <b>Aanmelden:</b><br />
                            via <a href="mailto:info@jettiescholten.nl">info@jettiescholten.nl</a><br />
                            Geef gauw op welke datum(s) je meedoet want er is beperkt plek!<br />
                            Geef - naast je eigen naam - ook naam en telefoonnummer van een ouder/verzorger op.<br />
                        </div>

                    </div>
                </div>
            </>
    },
    {
        id: "mijnWerk",
        jsxCode:
            <>
                <div className="text-content">
                    <h1>MIJN <span className="emp">WERK</span></h1>
                    Met oog voor detail, een luisterend oor, en gevoel voor vorm en kleur
                    zorg ik steeds voor een mooi resultaat.
                </div>
                <MyWorkItemsComponent />
            </>
    },
    {
        id: "uwVerhaal",
        jsxCode:
            <>
                <div className="text-content">
                    <h1>UW <span className="emp">VERHAAL</span></h1>
                    Iedere onderneming heeft een eigen verhaal. Dit is uniek en als u dit op de juiste manier inzet uw kracht. Ik kan uw verhaal op een professionele en verfijnde manier visualiseren om die zo op de
                    beste manier in te zetten. U kent dat verhaal als geen ander, dus staat u zelf centraal bij ieder project.<br /><br />
                    Met oog voor detail, een luisterend oor, en gevoel voor vorm en kleur zorg ik steeds voor een mooi resultaat.
                </div>
                <MyWorkItemsComponent />
            </>
    },
    {
        id: "mijnVerhaal",
        jsxCode:
            <>
                <div className="text-content">
                    <h1>MIJN <span className="emp">VERHAAL</span></h1>
                    Ook mijn eigen verhaal is uniek. Met jarenlange ervaring als vormgever en marketeer heb ik inmiddels aan heel wat mooie projecten mogen werken.
                    <br /><br />
                    Ik heb een achtergrond als marketeer na een internationale studie Internationaal Management in Nederland en Duitsland. Maar ook als creatieveling met een grafische opleiding in Engeland. Waar ik ook als kind ben opgegroeid.
                    <br /><br />
                    Voordat ik als freelancer aan de slag ging heb ik in verschillende functies als marketeer gewerkt. In die functies was ik altijd in nauw contact met vormgevers en reclamebureaus. Ik kwam erachter dat ik heel graag aan de andere kant van de tafel wilde zitten. Dit doe ik nu al een aantal jaren met veel
                    plezier.
                </div>
                <MyWorkItemsComponent />
            </>
    },
    {
        id: "contact",
        jsxCode:
            <>
                <div className="text-content">
                    <h1>CONTACT</h1>
                    Jester Marketing & Design<br />
                    info@jestermarketing.nl<br />
                    Tel. 06 55 88 58 68<br />
                </div>
            </>
    }
];