export interface MenuItem {
    linkName: string;
    description: JSX.Element;
}

export const MenuItems: MenuItem[] = [
    { linkName: "mijnWerk", description: <div className="menu-item-text">mijn <span className="emp">werk</span></div> },
    { linkName: "uwVerhaal", description: <div className="menu-item-text">uw <span className="emp">verhaal</span></div> },
    { linkName: "mijnVerhaal", description: <div className="menu-item-text">mijn <span className="emp">verhaal</span></div> },
    { linkName: "workshops", description: <div className="menu-item-text">workshops</div>},
    { linkName: "contact", description: <div className="menu-item-text">contact</div> }

]