import { WorkItem } from "./WorkItem";

export const illustations: WorkItem[] = [
    { id: "illustratie01", title: "Illustratie", tileImage: "illustration01", detailImage: "illustration01", description: null },
    { id: "illustratie04", title: "Illustratie", tileImage: "illustration04", detailImage: "illustration04", description: null },
    { id: "illustratie05", title: "Illustratie", tileImage: "illustration05", detailImage: "illustration05", description: null },
    { id: "illustratie06", title: "Illustratie", tileImage: "illustration06", detailImage: "illustration06", description: null },
    { id: "illustratie07", title: "Illustratie", tileImage: "illustration07", detailImage: "illustration07", description: null },
    { id: "illustratie08", title: "Illustratie", tileImage: "illustration08", detailImage: "illustration08", description: null },
    { id: "illustratie09", title: "Illustratie", tileImage: "illustration09", detailImage: "illustration09", description: null },
    { id: "illustratie10", title: "Illustratie", tileImage: "illustration10", detailImage: "illustration10", description: null },
    { id: "illustratie11", title: "Illustratie", tileImage: "illustration11", detailImage: "illustration11", description: null },
    { id: "illustratie02", title: "Illustratie", tileImage: "illustration02", detailImage: "illustration02", description: null },
    { id: "illustratie03", title: "Illustratie", tileImage: "illustration03", detailImage: "illustration03", description: null }
]

export const workItems: WorkItem[] = [
    { id: "cachet", title: "Luxe brochure", tileImage: "cachet", detailImage: "cachet", description: "Luxe brochure met hoogwaardige     fotografie ter ondersteuning van     een collectie gordijnstoffen uit     het hogere segment: Cachet by     Holland Haag." },
    { id: "byronJonesAdvert", title: "Advertentie", tileImage: "byronjonesadvert", detailImage: "byronjonesadvert", description: "Advertentie voor Woonwinkel en interieurarchitect Byron & Jones." },
    { id: "catchKaarten", title: "Ansichtkaarten", tileImage: "ansichtkaarten", detailImage: "ansichtkaarten", description: "Ansichtkaarten ter ondersrteuning van de verkoop van het interieurmerk Catch by Holland Haag." },
    { id: "boekcover", title: "Boekcover", tileImage: "dagboek", detailImage: "dagboek", description: "Illustratie boekomslag en vormgeving inhoud boek." },
    { id: "chivassoMoodboards", title: "Moodboards", tileImage: "moodboards", detailImage: "moodboards", description: "I made these tactile moodboards to illustrate the inspiration behind an interior fabrics collection for Chivasso fabrics and wallpapers." },
    { id: "passenger", title: "Behang stalenboek", tileImage: "passenger", detailImage: "passenger", description: "Styling en begeleiding foto’s en vormgeving stalenboek ter ondersteuning vam de verkoop van deze luxe behangcollectie." },
    { id: "bites", title: "Huisstijl eetcafe", tileImage: "bites", detailImage: "bites", description: "Huisstijl eetcafe in Luxemburg." },
    { id: "barBotanique", title: "Fotografie", tileImage: "botanique", detailImage: "botanique", description: "Fotografie Bar Botanique in Amsterdam." },
    { id: "cosi", title: "Brochure merklancering", tileImage: "cosi", detailImage: "cosi", description: "llustraties, fotobewerking en vormgeving van een luxe brochure inclusief begeleiding fotoshoot en styling.\r\nDe brochure werd uitgegeven om het nieuwe merk Cosi by Holland Haag te lanceren." },
    { id: "byronJonesTekening", title: "Illustratie", tileImage: "byronjonestekening", detailImage: "byronjonestekening", description: "Illustratie Woonwinkel Byron & Jones" },
    { id: "stalenboeken", title: "Stalenboeken", tileImage: "stalenboeken", detailImage: "stalenboeken", description: "Stalenboeken voor Carlucci." },
    { id: "laMie", title: "Huisstijl eetcafe", tileImage: "lamie", detailImage: "lamie", description: "Huisstijl eetcafe in Luxemburg. Illustraties gemaakt op basis van vintage illustraties uit het archief van het Rijksmuseum." },
    { id: "chivassoBrochure", title: "Luxe brochure", tileImage: "chivassobrochure", detailImage: "chivassobrochure", description: "Luxe brochure met hoogwaardige fotografie ter ondersteuning van een collectie interieurstoffen uit het hogere segment: Chivasso" },
    { id: "carlucciPosters", title: "Posters beursstand", tileImage: "carlucciposters", detailImage: "carlucciposters", description: "Vormgeving posters ten behoeve van luxe beursstand op de Internatinale Möbel Messe in Keulen voor het merk Carlucci di Chivasso." },
    { id: "midzomer", title: "Huisstijl zomerfestival", tileImage: "midzomer", detailImage: "midzomer", description: "Huisstijl voor het zomerfestival: Midzomernacht in Dorst." },
    { id: "jabContract", title: "Materiaalborden", tileImage: "jabcontract", detailImage: "jabcontract", description: "Materiaalborden ter illustratie van  een nieuwe collectie interieurstoffen voor de collectie van JAB Contract." },
    { id: "carlucciBrochure", title: "Luxe Brochure", tileImage: "carluccibrochure", detailImage: "carluccibrochure", description: "Luxe brochure met hoogwaardige fotografie ter ondersteuning van een collectie interieurstoffen uit het hogere segment voor het merk Carlucci di Chivasso." },
    { id: "languageCollective", title: "Logo", tileImage: "collective", detailImage: "collective", description: "Ontwerp logo talenschool: The Language Collective in Berlijn." },
    { id: "heimtextilPosters", title: "Posters en logo's beursstand", tileImage: "heimtextilposters", detailImage: "heimtextilposters", description: "Vormgeving posters ten behoeve van een beursstand op de Heimtextil Messe in Frankfurt voor grrothandel in raamdecoratie: Holland Haag." },
    { id: "adviesraad", title: "Huisstijl Adviesraad Sociaal Domein Zoetermeer", tileImage: "adviesraad", detailImage: "adviesraad", description: "Huisstijl voor de Adviesraad Sociaal Domein Zoetermeer." },
    { id: "cachetFotografie", title: "Productfotografie", tileImage: "cachetfotografie", detailImage: "cachetfotografie", description: "Productfotografie van gordijnstoffen van het merk Cachet by Holland Haag." },
    { id: "induvac", title: "Posters", tileImage: "induvac", detailImage: "induvac", description: "Ontwerp posters ten behoeve van promotie van het bedrijf Induvac." }
];